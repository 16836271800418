@import "modules/variables";
@import "modules/mixins";

h1 {
  @media #{$tablet} {
    font-size: rem(96);

    &.light {
      font-size: rem(64);
    }

    &.small {
      font-size: rem(96*0.8);
    }
  }
  @media #{$desktop} {
    font-size: rem(104);

    &.light {
      font-size: rem(64);
    }

    &.small {
      font-size: rem(104*0.8);
    }
  }
}
h2 {
  @media #{$tablet} {
    font-size: rem(48);

    &.light {
      font-size: rem(40);
    }
  }
  @media #{$desktop} {
    font-size: rem(60);

    &.light {
      font-size: rem(40);
    }
  }
}
h4 {
  @media #{$tablet} {
    font-size: rem(28);

    &.light {
      font-size: rem(20);
    }
  }
  @media #{$desktop} {
    font-size: rem(32);

    &.light {
      font-size: rem(20);
    }
  }
}

/* Gallery */
.swiper__thumbs {
  display: none;
}

/* Footer */
.button--contact-me {
  color: $color-gray_dark-hex!important;

  &:hover {
    color: $color-primary-hex!important;
  }
}

/* Toggle list */
.toggle-list,
.faq-list {
  .toggle-list__item__content,
  .faq-item__answer {
    height: auto;
    display: none;
  }
}

/* Tabs */
.tabs {
  
}