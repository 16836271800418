@function rem($val) {
  @return $val / 16 * 1rem;
}

@function grid-max($val) {
  @return calc((#{$grid-col_width} * #{$val}) + (#{$grid-gutter} * #{$val - 1}));
}

@mixin object-fit($val: cover, $position: false) {
  $polyfill: 'object-fit: #{$val};';

  @if $position {
    object-position: $position;
    $polyfill: 'object-fit: #{$val}; object-position: #{$position};';
  }

  object-fit: $val;
  //Polyfill handles by ofi.min.js
  font-family: $polyfill;
}

@mixin clip-text($lines: 3, $line-height: 1.44em, $color: $color-gray_lightest-hex, $offset: 0px) {
  max-height: $lines * $line-height;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: $offset;
    top: ($lines - 1) * $line-height;
    height: $line-height;
    width: 25%;
    background: linear-gradient(to right, rgba($color, 0), $color);
  }
}
@mixin clip-text-color($color: $color-white-hex) {
  &:after {
    background: linear-gradient(to right, rgba($color, 0), $color);
  }
}

@mixin container($column: false) {
  box-sizing: content-box;
  @if(not $column) {
    max-width: $grid-width;
    padding: 0 $grid-offset;
  }
  @else {
    max-width: calc(#{$grid-width} + #{$grid-gutter});
    padding-left: calc(#{$grid-offset} - (#{$grid-gutter} / 2));
    padding-right: calc(#{$grid-offset} - (#{$grid-gutter} / 2));
  }

  margin-left: auto;
  margin-right: auto;
}

@mixin list-style($margin: true, $ul: true, $ol: true) {
  ul,
  ol {
    @if($margin) {
      margin: 0 0 $size-smaller;
    }
    padding-left: $size-smaller;

    ul,
    ol {
      margin: 0;
    }

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @if($ul) {
    ul {
      li {
        list-style: disc;
  
        li {
          list-style: circle;
  
          li {
            list-style: square;
          }
        }
      }
    }
  }
  @if($ol) {
    ol {
      li {
        list-style: decimal;
  
        li {
          list-style: lower-alpha;
  
          li {
            list-style: square;
          }
        }
      }
    }
  }
}