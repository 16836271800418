/* --------
  Sass vars based on the css vars to make them easier to reference
-------- */
//Colors
$color-primary: var(--color-primary);
$color-primary_light: var(--color-primary_light);
$color-gray_dark: var(--color-gray_dark);
$color-gray_medium: var(--color-gray_medium);
$color-gray_light: var(--color-gray_light);
$color-gray_lighter: var(--color-gray_lighter);
$color-gray_lightest: var(--color-gray_lightest);
$color-white: var(--color-white);

//Colors as hex (when needed)
$color-primary-hex: #C2D532;
$color-primary_light-hex: #E0EA98;
$color-gray_dark-hex: #1B2428;
$color-gray_medium-hex: #A9B2BA;
$color-gray_light-hex: #CACED2;
$color-gray_lighter-hex: #EDEDED;
$color-gray_lightest-hex: #F5F5F5;
$color-white-hex: #ffffff;
$color-debug: #7B67FB;

//Grid
$grid-cols: 6;//Will never change so we can leave it as a Sass var
$grid-col_width: var(--grid-col_width);
$grid-gutter: var(--grid-gutter);
$grid-width: var(--grid-width);
$grid-offset: var(--grid-offset);

//Fonts and font sizes
$font: 'DINNextLTPro', sans-serif;//Will never change so we can leave it as a Sass var
$font_condensed: 'DINNextLTPro-Condensed', sans-serif;

$font-size-normal: var(--font-size-normal);
$font-size-small: var(--font-size-small);
$font-size-h1: var(--font-size-h1);
$font-size-h2: var(--font-size-h2);
$font-size-h3: var(--font-size-h3);
$font-size-h4: var(--font-size-h4);
$font-size-h1-light: var(--font-size-h1-light);
$font-size-h2-light: var(--font-size-h2-light);
$font-size-h3-light: var(--font-size-h3-light);
$font-size-h4-light: var(--font-size-h4-light);

$weight-light: var(--weight-light);
$weight-medium: var(--weight-medium);
$weight-bold: var(--weight-bold);

//Sizes (margin and padding)
$size-largest: var(--size-largest);
$size-larger: var(--size-larger);
$size-large: var(--size-large);
$size-medium: var(--size-medium);
$size-normal: var(--size-normal);
$size-small: var(--size-small);
$size-smaller: var(--size-smaller);
$size-smallest: var(--size-smallest);
$size-grid: var(--size-grid);
$size-header: var(--size-header);

//Z-Index
$z-top: 1024;
$z-high: 512;
$z-middle: 256;
$z-low: 128;
$z-bottom: 32;
$z-bottom: 0;

//Media quearies
$desktop-large: "screen and (min-width: 1441px)";
$desktop: "screen and (min-width: 1120px)";
$tablet-large-up: "screen and (min-width: 800px)";
$tablet-large: "screen and (max-width: 1119px) and (min-width: 800px)";
$tablet: "screen and (max-width: 1119px) and (min-width: 700px)";
$tablet-up: "screen and (min-width: 700px)";
$tablet-down: "screen and (max-width: 1119px)";
$tablet-small: "screen and (max-width: 799px) and (min-width: 700px)";
$tablet-small-down: "screen and (max-width: 799px)";
$mobile: "screen and (max-width: 699px)";

$burger: $tablet-down;
$not-burger: $desktop;

$touch: "(hover: none)";
$not-touch: "(hover: hover)";
